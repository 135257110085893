import React, { useEffect, useContext, useState, useRef } from "react";
import { nanoid } from "nanoid";
import axios from "axios";
import { DateTime, Settings } from "luxon";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import TimeAgo from "javascript-time-ago";
import el from "javascript-time-ago/locale/el";
import Post from "./Post";
import { eirinodikia } from "./Eirinodikia";
import { protodikeia } from "./Protodikeia";
import { efeteia } from "./Efeteia";
import { dioikProtodikeia } from "./DioikProtodikeia";
import { dioikEfeteia } from "./DioikEfeteia";
import { ypothikofylakia } from "./Ypothikofylakia";
import { ktimat } from "./Ktimat";
import { dimoi } from "./Dimoi";
import { DOY } from "./DOY";
import { glosses } from "./Glosses";
import { el as gr, is } from "date-fns/locale";
import { useInterval } from "../../utils/useInterval";

import {
  SearchSelect,
  SearchSelectItem,
  Select,
  SelectItem,
  Text,
  DateRangePicker,
  Callout,
  Card,
  Button,
} from "@tremor/react";
import { InformationCircleIcon } from "@heroicons/react/solid";
import { CalendarIcon, DocumentReportIcon } from "@heroicons/react/outline";

import AuthContext from "../../context/AuthProvider";
import { formatKeepOnlyDate } from "../../utils/checkMisthosi";
import { set } from "date-fns";
import SimplePost from "./SimplePost";
import MonthlyCalendar from "../Calendar/MonthlyCalendar";
import { Link } from "react-router-dom";

export const eidi = [
  "ΕΙΡΗΝΟΔΙΚΕΙΟ",
  "ΠΡΩΤΟΔΙΚΕΙΟ",
  "ΕΦΕΤΕΙΟ",
  "ΔΙΟΙΚ.ΠΡΩΤΟΔΙΚΕΙΟ",
  "ΔΙΟΙΚ.ΕΦΕΤΕΙΟ",
  "ΥΠΟΘΗΚΟΦΥΛΑΚΕΙΟ",
  "ΚΤΗΜ.ΓΡΑΦΕΙΟ",
  "ΔΗΜΟΣ/ΛΗΞΕΙΑΡΧΕΙΟ",
  "ΔΟΥ",
  "ΜΕΤΑΦΡΑΣΕΙΣ",
];
export const eidiMap = {
  ΕΙΡΗΝΟΔΙΚΕΙΟ: eirinodikia,
  ΠΡΩΤΟΔΙΚΕΙΟ: protodikeia,
  ΕΦΕΤΕΙΟ: efeteia,
  "ΔΙΟΙΚ.ΠΡΩΤΟΔΙΚΕΙΟ": dioikProtodikeia,
  "ΔΙΟΙΚ.ΕΦΕΤΕΙΟ": dioikEfeteia,
  ΥΠΟΘΗΚΟΦΥΛΑΚΕΙΟ: ypothikofylakia,
  "ΚΤΗΜ.ΓΡΑΦΕΙΟ": ktimat,
  "ΔΗΜΟΣ/ΛΗΞΕΙΑΡΧΕΙΟ": dimoi,
  ΔΟΥ: DOY,
  ΜΕΤΑΦΡΑΣΕΙΣ: glosses,
};

export const eidiToEngMap = {
  ΕΙΡΗΝΟΔΙΚΕΙΟ: "eirin",
  ΠΡΩΤΟΔΙΚΕΙΟ: "protod",
  ΕΦΕΤΕΙΟ: "efeteia",
  "ΔΙΟΙΚ.ΠΡΩΤΟΔΙΚΕΙΟ": "dioik",
  "ΔΙΟΙΚ.ΕΦΕΤΕΙΟ": "dioikEfeteia",
  ΥΠΟΘΗΚΟΦΥΛΑΚΕΙΟ: "ypoth",
  "ΚΤΗΜ.ΓΡΑΦΕΙΟ": "ktimat",
  "ΔΗΜΟΣ/ΛΗΞΕΙΑΡΧΕΙΟ": "dimoi",
  ΔΟΥ: "DOY",
  ΜΕΤΑΦΡΑΣΕΙΣ: "metaf",
};
export const eidiToGrMap = {
  eirin: "ΕΙΡΗΝΟΔΙΚΕΙΟ",
  protod: "ΠΡΩΤΟΔΙΚΕΙΟ",
  efeteia: "ΕΦΕΤΕΙΟ",
  dioik: "ΔΙΟΙΚ.ΠΡΩΤΟΔΙΚΕΙΟ",
  dioikEfeteia: "ΔΙΟΙΚ.ΕΦΕΤΕΙΟ",
  ypoth: "ΥΠΟΘΗΚΟΦΥΛΑΚΕΙΟ",
  ktimat: "ΚΤΗΜ.ΓΡΑΦΕΙΟ",
  dimoi: "ΔΗΜΟΣ/ΛΗΞΕΙΑΡΧΕΙΟ",
  DOY: "ΔΟΥ",
  metaf: "ΜΕΤΑΦΡΑΣΕΙΣ",
};

TimeAgo.addLocale(el);
const timeAgo = new TimeAgo("el-GR");

Settings.defaultLocale = "el";

Date.createFromMysql = function (mysql_string) {
  var t,
    result = null;

  if (typeof mysql_string === "string") {
    t = mysql_string.split(/[- :]/);

    //when t[3], t[4] and t[5] are missing they defaults to zero
    result = new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0);
  }

  return result;
};
export function formatDate(date) {
  const newDate = new Date(Date.createFromMysql(date));

  const formatedDate = DateTime.fromISO(newDate.toISOString())
    .plus({ minutes: Math.abs(new Date().getTimezoneOffset()) })
    .toUnixInteger();
  const unixInt = new Date(formatedDate * 1000);
  return timeAgo.format(unixInt);
}

const Ekloges = () => {
  const [value, setValue] = React.useState("1");
  const { avatarUploaded, notifLength } = React.useContext(AuthContext);
  const [body, setBody] = React.useState("");
  const [uuid, setUuid] = React.useState(nanoid());
  const [initialPosts, setInitialPosts] = React.useState([]);
  const [posts, setPosts] = React.useState([]);
  const [showCreatePost, setShowCreatePost] = React.useState(true);
  const [page, setPage] = useState(1);
  const [lastId, setLastId] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [eidos, setEidos] = useState("");
  const [periohi, setPeriohi] = useState("");
  const [periohes, setPeriohes] = useState(eidiMap[eidos]);
  const [loading, setLoading] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    from: null,
    to: null,
  });
  const [reportUUID, setReportUUID] = useState(null);
  const [isPostingViews, setIsPostingViews] = React.useState(false);

  const [dates, setDates] = useState([]);
  const [calUuid, setCalUuid] = useState(null);

  const sentryRef = useRef(null);

  const postViewCounter = async (isMounted, controller) => {
    try {
      const allViewsFromLocalStorage = getPostsFromLocalStorage();
      if (allViewsFromLocalStorage.length === 0) return;
      setIsPostingViews(true);

      const res = await axiosPrivate.post(
        "/viewCounter",
        {
          viewCounts: allViewsFromLocalStorage,
        },
        { signal: controller.signal }
      );
      // console.log(res.data);
      isMounted && setIsPostingViews(false);
      isMounted && markPostsAsSent(allViewsFromLocalStorage);
    } catch (err) {
      console.error(err);
      if (!axios.isCancel(err)) {
        // do something
        navigate("/login", { state: { from: location }, replace: true });
      }
    }
  };

  useInterval(async () => {
    if (isPostingViews) return;
    let isMounted = true;
    const controller = new AbortController();
    await postViewCounter(isMounted, controller);
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, 1000);
  const getPostsFromLocalStorage = () => {
    const posts = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith("post-")) {
        const item = localStorage.getItem(key);
        if (item === "sent") continue;
        posts.push({
          [key]: item,
        });
      }
    }
    return posts;
  };

  const markPostsAsSent = (posts) => {
    posts.forEach((post) => {
      const key = Object.keys(post)[0];
      localStorage.setItem(key, "sent");
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && posts.length > 0) {
          // #sentry element is in view
          console.log("#sentry is in view");
          getPosts(false);
          setLastId(posts[posts.length - 1].id);
        }
      });
    });

    if (sentryRef.current) {
      observer.observe(sentryRef.current);
    }

    return () => {
      if (sentryRef.current) {
        observer.unobserve(sentryRef.current);
      }
    };
  }, [posts]);

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  function deletePost(uuid) {
    setInitialPosts(initialPosts.filter((post) => post.uuid !== uuid));
    setPosts(posts.filter((post) => post.uuid !== uuid));
  }

  useEffect(() => {
    setPeriohes(eidiMap[eidos]);
  }, [eidos]);

  useEffect(() => {
    const newPostElement = document.getElementById("new-post");
    const newPostHeight = newPostElement.offsetHeight;
    const count = body.split("\n").length - 1;

    newPostElement.style.height = `${
      (Math.ceil(body.length / 56) + count) * 25
    }px`;
  }, [body]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getPosts = async () => {
      try {
        const response = await axiosPrivate.get("/posts/all", {
          signal: controller.signal,
        });
        isMounted &&
          setInitialPosts((prevPosts) => [...prevPosts, ...response.data]);
        isMounted && setPosts((prevPosts) => [...prevPosts, ...response.data]);
        isMounted && setLastId(response.data[response.data.length - 1].id);
        isMounted && setTotalCount(response.data[0].count);
      } catch (err) {
        console.error(err);
        if (!axios.isCancel(err)) {
          // do something
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };

    getPosts();

    // const interval = setInterval(() => {
    //   getProthesmies()
    // }, 10000)

    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifLength]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getPosts = async () => {
      try {
        const response = await axiosPrivate.get("/calendar/small", {
          signal: controller.signal,
        });
        isMounted && setDates(response.data.small);
        isMounted && setCalUuid(response.data.calUuid.uuid);
      } catch (err) {
        console.error(err);
        if (!axios.isCancel(err)) {
          // do something
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };

    getPosts();

    // const interval = setInterval(() => {
    //   getProthesmies()
    // }, 10000)

    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPosts = async (created) => {
    console.log("getPosts");
    try {
      let response;
      if (!created) {
        response = await axiosPrivate.get("/posts/all", {
          params: { page: lastId },
        });
      }
      if (created) {
        response = await axiosPrivate.get("/posts/all", {
          params: { page: undefined },
        });
      }

      setInitialPosts((prevPosts) => [...prevPosts, ...response.data]);
      setPosts((prevPosts) => [...prevPosts, ...response.data]);
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const createReport = async () => {
    try {
      setReportLoading(true);
      const response = await axiosPrivate.get("/report");
      console.log("report", response.data);
      setReportUUID(response.data.uuid);
      setReportLoading(false);
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const handleCreate = async (e) => {
    e.preventDefault();

    try {
      await axiosPrivate.post("/posts", JSON.stringify({ body, uuid }), {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      // forceUpdate()
      //console.log(JSON.stringify(response));
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("dateRange", dateRange);

    try {
      await handleCreate(e);
      await axiosPrivate.post(
        `/posts/${uuid}`,
        JSON.stringify({
          body: body.replaceAll("\n", " \n "),
          eidos: eidiToEngMap[eidos],
          periohi,
          dateRange: {
            from: formatKeepOnlyDate(dateRange.from),
            to: formatKeepOnlyDate(dateRange.to),
          },
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      setBody("");
      setEidos("");
      setPeriohi("");
      setUuid(nanoid());
      getPosts(true);
      // forceUpdate()
      //console.log(JSON.stringify(response));
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };
  function showGrammateisPosts() {
    const grammateisPosts = initialPosts.filter(
      (post) => post.grammateas === 1
    );
    setPosts(grammateisPosts);
  }
  function showDikastikoiPosts() {
    const dikastikoiPosts = initialPosts.filter(
      (post) => post.grammateas === 0
    );
    setPosts(dikastikoiPosts);
  }
  // if post.pinned === 1 then it is pinned and it should be at the top
  const filteredPosts = posts
    .filter((post, index, self) => {
      return index === self.findIndex((t) => t.uuid === post.uuid);
    })
    .sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    })
    .sort((a, b) => {
      return b.pinned - a.pinned;
    });

  return (
    <>
      <div className="start-page">
        <div className="calendar large">
          <MonthlyCalendar dates={dates} />
          {!reportUUID && (
            <Button
              onClick={createReport}
              icon={DocumentReportIcon}
              className="w-full"
            >
              {reportLoading ? (
                <div className="loader"></div>
              ) : (
                "Δημιουργία Report σε Word"
              )}
            </Button>
          )}
          {reportUUID && (
            <a href={`/api/public/${reportUUID}/mysolon-report.docx`}>
              Αναφορά
            </a>
          )}
          <br />
        </div>
        <div className="calendar small">
          <MonthlyCalendar dates={dates} />
          {!reportUUID && (
            <Button
              onClick={createReport}
              icon={DocumentReportIcon}
              className="w-full"
            >
              {reportLoading ? (
                <div className="loader"></div>
              ) : (
                "Δημιουργία Αναφοράς"
              )}
            </Button>
          )}
          {reportUUID && (
            <a href={`/api/public/${reportUUID}/mysolon-report.docx`}>
              Αναφορά
            </a>
          )}
        </div>

        <div className="ekloges-section">
          <div className="flex flex-col">
            <a
              href={`webcal://mysolon.gr/api/webcal/${calUuid}`}
              className="text-xs break-all"
            >
              <Button icon={CalendarIcon} color="sky" className="w-full">
                Συγχρονισμός Ημερολογίου
              </Button>
              <br />
              {`webcal://mysolon.gr/api/webcal/${calUuid}`}
            </a>
            <a
              href={`webcal://mysolon.gr/api/webcalproth/${calUuid}`}
              className="text-xs break-all mt-2"
            >
              <Button icon={CalendarIcon} color="teal" className="w-full">
                Συγχρονισμός Ημερολογίου Προθεσμιών
              </Button>
              <br />
              {`webcal://mysolon.gr/api/webcalproth/${calUuid}`}
              <br />
              <p className="text-xs">
                Οι προθεσμίες υπολογίζονται αυτόματα και είναι ενδεικτικές
              </p>
            </a>
          </div>

          {showCreatePost && (
            <div className="create_post">
              <textarea
                id="new-post"
                value={body}
                placeholder="Γράψτε το μήνυμά σας εδώ..."
                onChange={(e) => setBody(e.target.value)}
                className="ekloges-post-create"
              />
              {!periohi && (
                <div className="post-buttons">
                  <button
                    onClick={handleSubmit}
                    className="addcase_modal check_now ekloges_buttons"
                  >
                    Αποστολή
                  </button>
                </div>
              )}
              {/* <Callout
            className="h-50 mt-4 mb-4 max-w-[450px]"
            title="Αναζήτηση συναδέλφων δικηγόρων"
            icon={InformationCircleIcon}
            color="emerald"
          >
            Εάν αναζητείτε συνάδελφο για να σας βοηθήσει σε διεκπεραίωση κάποιας
            εργασίας, επιλέγετε παρακάτω είδος και τόπο. Θα ειδοποιηθούν οι
            συνάδελφοι που έχουν επιλέξει το ίδιο είδος και τόπο, για να
            εκδηλώσουν μετέπειτα το ενδιαφέρον τους.
          </Callout> */}
              <div className="aggelies-form mt-1">
                <Select
                  placeholder="Είδος"
                  onValueChange={setEidos}
                  value={eidos}
                >
                  {eidi.map((dim, i) => {
                    return (
                      <SelectItem key={`${i}-${dim}`} value={dim}>
                        {dim}
                      </SelectItem>
                    );
                  })}
                </Select>

                {eidos && (
                  <SearchSelect
                    placeholder={eidos === "ΜΕΤΑΦΡΑΣΕΙΣ" ? "Γλώσσα" : "Τόπος"}
                    onValueChange={setPeriohi}
                    value={periohi}
                  >
                    {eidiMap[eidos]?.map((dim, i) => {
                      return (
                        <SearchSelectItem key={`${i}-${dim}`} value={dim}>
                          {dim}
                        </SearchSelectItem>
                      );
                    })}
                  </SearchSelect>
                )}
                {periohi && (
                  <DateRangePicker
                    className="max-w-md mx-auto"
                    value={dateRange}
                    onValueChange={setDateRange}
                    locale={gr}
                    enableSelect={false}
                    placeholder="Επιλέξτε Χρον.Διάστημα"
                  />
                )}
              </div>

              {/* <Toggle value={value} onValueChange={setValue}>
            <ToggleItem value="1" text={"Γραμματέα"} />
            <ToggleItem value="2" text={"Δικ.Αντιπρόσωπο"} />
          </Toggle> */}
              {periohi && (
                <div className="post-buttons">
                  <button
                    onClick={handleSubmit}
                    className="addcase_modal check_now ekloges_buttons"
                  >
                    Αποστολή
                  </button>
                </div>
              )}
            </div>
          )}

          {!showCreatePost && (
            <div className="show-buttons">
              <button
                onClick={() => showDikastikoiPosts()}
                className="post-footer-badge-grammateas"
              >
                Γραμματείς
              </button>
              <button
                onClick={() => showGrammateisPosts()}
                className="post-footer-badge"
              >
                Δικαστικοί
              </button>
            </div>
          )}
          {/* show posts with unique uuid */}
          {filteredPosts.map((post, i) => (
            <div className="ekloges-post" key={`${lastId}-${post.uuid}`}>
              <Post
                creator={`${post.user?.first_name} ${post.user?.last_name}`}
                email={post.user?.email}
                timeSince={formatDate(post?.created_at)}
                body={post.body}
                postUuid={post.uuid}
                avatar={post.user?.avatar_uuid}
                uuid={post.user?.uuid}
                avatarUploaded={avatarUploaded}
                postCreatorId={post.user?.id}
                deletePost={deletePost}
                grammateas={post.grammateas}
                post={post}
                getPosts={getPosts}
              />
            </div>
          ))}
          {filteredPosts.length < totalCount && (
            <div ref={sentryRef} id="sentry">
              Loading...
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Ekloges;
