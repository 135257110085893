import { useRef, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { LineChart, Card, Title } from "@tremor/react";
const userData = [
  { week: "2023-08", count: 144 },
  { week: "2023-09", count: 39 },
  { week: "2023-10", count: 22 },
  { week: "2023-11", count: 334 },
  { week: "2023-12", count: 89 },
  { week: "2023-13", count: 61 },
  { week: "2023-14", count: 23 },
  { week: "2023-15", count: 15 },
  { week: "2023-16", count: 165 },
  { week: "2023-17", count: 50 },
  { week: "2023-18", count: 30 },
  { week: "2023-19", count: 115 },
  { week: "2023-20", count: 451 },
  { week: "2023-21", count: 21 },
  { week: "2023-22", count: 64 },
  { week: "2023-23", count: 26 },
  { week: "2023-24", count: 11 },
];

const userDataAddAllPrevious = userData.map((item, index) => {
  let sum = 0;
  for (let i = 0; i <= index; i++) {
    sum += userData[i].count;
  }
  return { ...item, count: sum };
});
const dataFormatter = (number) =>
  `${Intl.NumberFormat("us").format(number).toString()}%`;

import axios from "../api/axios";
import Stats from "./Stats/Stats";
const LOGIN_URL = "/auth";

const Login = () => {
  const { setAuth, persist, setPersist } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [info, setInfo] = useState({
    astikes: "",
    users: "",
    decisions: "",
    astikaCStats: "",
    adjCStats: "",
  });

  // useEffect(() => {
  //   userRef.current.focus();
  // }, [])

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);
  useEffect(() => {
    document.title = "Σύνδεση | MySolon.gr";
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ email: user.toLowerCase(), password: pwd }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      // console.log(JSON.stringify(response?.data));
      const accessToken = response?.data?.accessToken;
      const role = response?.data?.role;
      const uuid = response?.data?.uuid;
      const avatar = response?.data?.avatar;
      const id = response?.data?.id;
      setAuth({ user, pwd, role, accessToken, uuid, avatar, id });
      setUser("");
      setPwd("");
      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  const togglePersist = () => {
    setPersist((prev) => !prev);
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getInfo = async () => {
      try {
        const response = await axios.get("/info", {
          signal: controller.signal,
        });
        isMounted && setInfo(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    getInfo();
    // const interval = setInterval(() => {
    //   getCases()
    // }, 10000)
    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist]);

  return (
    <>
      <section className="container">
        <div className="solon-info">
          <a href="https://tax.mysolon.gr">
            Υπολογισμός Φορολόγιας Βάσει του Σχεδίου Νόμου για την Πάταξη της
            Φοροδιαφυγής
          </a>
          <p>
            To <span className="bold">MySolon.gr</span> ελέγχει καθημερινά{" "}
            <span className="bold">{info.astikes - info.decisions}</span>{" "}
            αστικές και <span className="bold">{info.dioikitikes}</span>{" "}
            διοικητικές υποθέσεις για λογαριασμό{" "}
            <span className="bold">{info.users}</span> χρηστών.{" "}
            <span className="bold">{info.decisions}</span> αποφάσεις έχουν ήδη
            εκδοθεί.{" "}
          </p>
        </div>
        <br />
        <div className="mertz">
          <p>
            Η εφαρμογή φτιάχτηκε από τον δικηγόρο Γιάννη Μερτζάνη και
            απευθύνεται
            <b> αποκλειστικά σε δικηγόρους</b>.
          </p>
        </div>

        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        {/* <h1>Sign In</h1> */}
        <form className="form" onSubmit={handleSubmit}>
          {/* <label htmlFor="username">Email:</label> */}
          <div className="input-container ic1">
            <input
              className="input"
              placeholder=" "
              type="text"
              id="username"
              ref={userRef}
              autoComplete="on"
              onChange={(e) => setUser(e.target.value)}
              value={user}
              required
            />
            <div className="cut"></div>
            <label htmlFor="email" className="placeholder">
              email
            </label>
          </div>

          {/* <label htmlFor="password">Password:</label> */}
          <div className="input-container ic2">
            <input
              className="input"
              type="password"
              id="password"
              placeholder=" "
              autoComplete="on"
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
              required
            />
            <div className="cut"></div>
            <label htmlFor="password" className="placeholder">
              password
            </label>
          </div>
          <button type="text" className="submit">
            Σύνδεση
          </button>
          <div className="persistCheck">
            <input
              type="checkbox"
              id="persist"
              onChange={togglePersist}
              checked={persist}
            />
            <label htmlFor="persist"> Παραμείνετε συνδεδεμένος</label>
          </div>
        </form>
        <p>
          Δεν έχετε λογαριασμό;
          <br />
          <span className="line">
            <Link to="/register">Εγγραφή</Link>
          </span>
        </p>
        <p>
          Ξεχάσατε τoν κωδικό σας;
          <br />
          <span className="line">
            <Link to="/forgot">Επαναφορά</Link>
          </span>
        </p>
      </section>
      <Stats stats={info} />
    </>
  );
};

export default Login;
